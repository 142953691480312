/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import BlogPostSingle from "../components/BlogPostSingle/BlogPostSingle"
import Footer from "../components/Footer/Footer"
import SeoPage from "../components/seo-page"

const RegulationPostTemplate = ({ data: { regulation } }) => {
  regulation.isRegulation = true
  return (
    <Layout>
      <SeoPage
        {...(regulation.seo?.focuskw && { keyword: regulation.seo?.focuskw })}
        {...(regulation.seo?.metaKeywords && {
          slug: regulation.seo.metaKeywords,
        })}
        {...(regulation.seo?.title && { title: regulation.seo?.title })}
        {...(regulation.seo?.metaDesc && {
          description: regulation.seo?.metaDesc,
        })}
        {...(regulation.seo?.opengraphImage?.link && {
          image: regulation.seo?.opengraphImage?.link,
        })}
      />
      <Header />
      <BlogPostSingle post={regulation} />
      <Footer />
    </Layout>
  )
}

export default RegulationPostTemplate

export const pageQuery = graphql`
  query RegulationPostById($id: String!) {
    regulation: wpRegulation(id: { eq: $id }) {
      id
      title
      content
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphImage {
          link
        }
      }
      date(formatString: "MMMM DD, YYYY")
      link
    }
  }
`
